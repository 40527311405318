/** @format */

import { Form, notification, Select } from "antd";
import CustomSelect from "../../../../../components/CustomSelect";
import SearchIcon from "../../../../../assets/icons/search.svg";
import { Departement, Lieux, Pays } from "../../../../../models";
import CustomInput from "../../../../../components/CustomInput";
import React, { useEffect, useState } from "react";
import LocaltionModal from "./LocaltionModal";
import { LocationFormModel, LocationModel, LocationType, TrajetPopupType } from "../models/TrajetModel";
import { doOnSubscribe, useRxEffect } from "../../../../../helper/rxjs.helper";
import { DepartementAPI } from "../../../../../domain/apis/departement.api";
import { finalize, of, tap } from "rxjs";
import useDebounce from "../../../../../hooks/useDebounce";
import SingleLoading from "../../../../../components/SingleLoading";

const { Option } = Select;

type Props = {
  initValues: LocationModel;
  lieuxData: Lieux[];
  paysData: Pays[];
  locationType: LocationType;
  onValidate: number;
  callBackData: (value: LocationFormModel) => void;
};

export default function PickLocation({
  initValues,
  lieuxData,
  paysData,
  locationType,
  onValidate,
  callBackData,
}: // callBackLatlng,
// callBackRequest,
Props) {
  const [form] = Form.useForm();

  const [departementsData, setDepartementsData] = useState<Departement[]>([]);
  const [popupType, setPopupType] = useState<TrajetPopupType>(TrajetPopupType.LIEUX);
  const [formData, setFormData] = useState<LocationFormModel>({});
  const [popupShow, setPopupShow] = useState<boolean>(false);
  // const [dataFetchPTV, setDataFetchPTV] = useState<PTVLocation | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cbForm, setCbForm] = useState<LocationFormModel>({});
  const [isFirst, setIsFirst] = useState<boolean>(true);

  useEffect(() => {
    setFormData(initValues);
  }, [JSON.stringify(initValues)]);

  useEffect(() => {
    form.setFieldsValue(formData);
  }, [JSON.stringify(formData)]);

  useEffect(() => {
    form.validateFields();
  }, [onValidate]);

  useRxEffect(() => {
    if (formData.paysId) {
      return DepartementAPI.fetchAll({
        filter: {
          where: {
            paysId: formData.paysId,
          },
        },
      }).pipe(
        doOnSubscribe(() => setIsLoading(true)),
        tap({
          next: (res: Departement[]) => {
            res.filter((x, i, a) => {
              return a.indexOf(x) == i;
            });
            setDepartementsData(res);
          },
        }),
        finalize(() => setIsLoading(false))
      );
    }
    setDepartementsData([]);
    return of([]);
  }, [formData.paysId]);

  useEffect(() => {
    if (!formData.paysId) {
      setFormData({
        lieuxId: undefined,
        paysId: undefined,
        departementId: undefined,
        codePostal: undefined,
        ville: undefined,
        adresse: undefined,
      });
    }
  }, [formData.paysId]);

  useEffect(() => {
    if (!formData.departementId) {
      setFormData({
        ...formData,
        lieuxId: undefined,
        codePostal: undefined,
        ville: undefined,
        adresse: undefined,
      });
      return;
    }
  }, [formData.departementId]);

  useEffect(() => {
    if (!formData.codePostal) {
      setFormData({
        ...formData,
        lieuxId: undefined,
        adresse: undefined,
      });
    }
  }, [formData.codePostal]);

  useEffect(() => {
    if (!formData.ville) {
      setFormData({
        ...formData,
        lieuxId: undefined,
        adresse: undefined,
      });
    }
  }, [formData.ville]);

  useEffect(() => {
    if (!formData.adresse) {
      setFormData({
        ...formData,
        lieuxId: undefined,
      });
    }
  }, [formData.adresse]);

  useDebounce(
    () => {
      if (isFirst) {
        setIsFirst(false);
        return;
      }
      callBackData(cbForm);
    },
    300,
    [cbForm]
  );

  function changeDepart() {
    const departId = form.getFieldValue('departementId')
    setFormData({
      ...formData,
      departementId: departId,
      codePostal: undefined,
      ville: undefined,
      lieuxId: undefined,
      adresse: undefined,
    });
  }

  function checkCodePostal() {
    const codeDepart = departementsData.find((el) => el.id === formData.departementId)?.code;
    if (codeDepart && formData?.codePostal && codeDepart !== formData?.codePostal?.slice(0, 2)) {
      notification.warning({
        key: "codePostal",
        message: "Ce Département n'est pas cohérent avec le Code Postal",
        placement: "top",
      });
    }
  }

  function onValuesChange() {
    const values = form.getFieldsValue();
    setFormData(values);
    setCbForm(values);
    // locationChanged(values);
    // if (!values.departementId || !values.paysId) {
    //   callBackLatlng(null);
    //   return;
    // }

    // callBackData(values);
    // findLocation(values);
  }

  function changePays(value: number) {
    setFormData({
      lieuxId: undefined,
      paysId: value,
      departementId: undefined,
      codePostal: undefined,
      ville: undefined,
      adresse: undefined,
    });
    setTimeout(() => {
      setCbForm({
        lieuxId: undefined,
        paysId: value,
        departementId: undefined,
        codePostal: undefined,
        ville: undefined,
        adresse: undefined,
      });
    }, 200);
  }

  return (
    <>
      {isLoading && <SingleLoading />}
      <Form form={form} onValuesChange={onValuesChange}>
        <LocaltionModal
          isShow={popupShow}
          popupType={popupType}
          dataForm={formData}
          onCancel={() => setPopupShow(false)}
          onSelected={(value) => {
            setPopupShow(false);
            form.setFieldsValue({
              ...formData,
              ...value,
            });
            onValuesChange();
          }}
          locationType={locationType}
        />
        <ul className="inputTrajet">
          <li>
            <Form.Item name="lieuxId">
              <CustomSelect
                bordered={false}
                style={{ height: "2rem" }}
                suffixIcon={<img className="searchIcon" src={SearchIcon} alt="Search" />}
                onClick={() => {
                  setPopupType(TrajetPopupType.LIEUX);
                  setPopupShow(true);
                }}
                dropdownStyle={{ display: "none" }}
              >
                {lieuxData.map((el, index) => {
                  return <Option key={index} value={el.id}>{`${el.code} - ${el.nom}`}</Option>;
                })}
              </CustomSelect>
            </Form.Item>
          </li>
          <li>
            {/* Select Pays */}
            <Form.Item name="paysId" rules={[{ required: true, message: "" }]}>
              <CustomSelect
                showArrow={false}
                bordered={false}
                style={{ height: "2rem" }}
                onSelect={(value: string | number) => {
                  changePays(value as number);
                }}
                allowClear={true}
              >
                {paysData.map((el, index) => {
                  return (
                    <Option key={index} value={el.id}>
                      {el.nom}
                    </Option>
                  );
                })}
              </CustomSelect>
            </Form.Item>
          </li>
          <li>
            {/* Select Department */}
            <Form.Item name="departementId" rules={[{ required: true, message: "" }]}>
              <CustomSelect showArrow={false} bordered={false} style={{ height: "2rem" }} allowClear={true} onChange={changeDepart}>
                {departementsData.map((el, index) => {
                  return (
                    <Option key={index} value={el.id}>
                      {el.code}
                    </Option>
                  );
                })}
              </CustomSelect>
            </Form.Item>
          </li>
          <li>
            {/* Select Code Postal */}
            <Form.Item name="codePostal">
              <CustomInput
                allowClear={true}
                maxLength={6}
                onChange={checkCodePostal}
                suffix={
                  <img
                    className="searchIcon"
                    src={SearchIcon}
                    alt="Search"
                    onClick={() => {
                      setPopupType(TrajetPopupType.CODE_POSTAL);
                      setPopupShow(true);
                    }}
                  />
                }
              />
            </Form.Item>
          </li>
          <li>
            {/* Select Ville */}
            <Form.Item name="ville">
              <CustomInput
                allowClear={true}
                suffix={
                  <img
                    className="searchIcon"
                    src={SearchIcon}
                    alt="Search"
                    onClick={() => {
                      setPopupType(TrajetPopupType.VILLE);
                      setPopupShow(true);
                    }}
                  />
                }
              />
            </Form.Item>
          </li>
          <li>
            {/* Select Address */}
            <Form.Item name="adresse">
              <CustomInput allowClear={true} />
            </Form.Item>
          </li>
        </ul>
      </Form>
    </>
  );
}
