/** @format */

import { Form, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { noop, tap } from "rxjs";
import DeleteIcon from "../../../../assets/icons/delete.svg";
import CustomInputNumber from "../../../../components/CustomInputNumber";
import { CoutsAdditionnelAPI } from "../../../../domain/apis/coutsAdditionnel.api";
import { Budget, CoutsAdditionnel, Devise } from "../../../../models";
import styles from "./Budget.module.css";
const { Option } = Select;

interface CoutsProps {
  index: number;
  itemData: CoutsAdditionnel;
  actionSave: boolean;
  listDevise: Devise[];
  coutsList: CoutsAdditionnel[];
  listCountAvailable: string[];
  changeListCoutsAvailable: () => void;
  itemCallBack: (index: number, item: FormModal) => void;
  changeCoutsList: (index: number, type: string) => void;
  parentCallback: (id: number) => void;
  submitCallBack: (index: number, statusItem: boolean) => void;
  editAble: boolean;
  budgetItem?: Budget;
}

interface FormModal {
  type?: string;
  montant?: number;
  deviseId?: number;
}

const CoutsItem = (props: CoutsProps) => {
  const {
    index,
    itemData,
    actionSave,
    parentCallback,
    submitCallBack,
    listDevise,
    changeCoutsList,
    listCountAvailable,
    coutsList,
    changeListCoutsAvailable,
    itemCallBack,
    editAble,
    budgetItem,
  } = props;
  const [form] = Form.useForm();
  const [formValue, setFormValue] = useState<FormModal>();

  useEffect(() => {
    if (budgetItem) {
      form.setFieldsValue({
        ...formValue,
        deviseId: budgetItem.prixContractualiseDeviseId,
      });
    }
  }, [budgetItem]);

  useEffect(() => {
    if (actionSave) {
      form.submit();
    }
  }, [actionSave]);

  useEffect(() => {
    let defaulValue = {
      ...itemData,
    };

    if (!defaulValue.deviseId) {
      defaulValue = {
        ...defaulValue,
        deviseId: listDevise.find((el) => el.nom === "€")?.id,
      };
    }
    form.setFieldsValue(defaulValue);
  }, [itemData, listDevise]);

  useEffect(() => {
    if (formValue?.type) {
      changeCoutsList(index, `${formValue.type}`);
      changeListCoutsAvailable();
    }
  }, [formValue?.type]);

  const onFinish = (values: FormModal) => {
    submitCallBack(index, true);
    itemCallBack(index, {
      ...itemData,
      ...values,
    });
  };

  const onFinishFailed = () => {
    submitCallBack(index, false);
  };

  const deleteItem = () => {
    if (itemData.id) {
      CoutsAdditionnelAPI.delete(itemData.id)
        .pipe(
          tap({
            next: () => {
              parentCallback(index);
            },
          })
        )
        .subscribe({ error: noop });
    } else {
      parentCallback(index);
    }
  };
  const onValuesChange = () => {
    const getFieldsValue = form.getFieldsValue();
    setFormValue(getFieldsValue);
  };

  return (
    <Form
      form={form}
      onFinishFailed={onFinishFailed}
      onFinish={onFinish}
      className={styles.listCout}
      onValuesChange={onValuesChange}
      disabled={!editAble}
    >
      <Input.Group compact>
        <Form.Item name="type" style={{ marginBottom: 0, width: "50%" }} rules={[{ required: true, message: "" }]}>
          <Select style={{ width: "100%" }} bordered={false}>
            {coutsList[index].type
              ? listCountAvailable.concat([`${coutsList[index].type}`]).map((el, index) => {
                  return (
                    <Option key={index} value={el}>
                      {el}
                    </Option>
                  );
                })
              : listCountAvailable.map((el, index) => {
                  return (
                    <Option key={index} value={el}>
                      {el}
                    </Option>
                  );
                })}
          </Select>
        </Form.Item>
        <Form.Item name="montant" style={{ marginBottom: 0, width: "30%" }} rules={[{ required: true, message: "" }]}>
          <CustomInputNumber precision={2} min={0.01} max={1000} />
        </Form.Item>
        <Form.Item name="deviseId" style={{ marginBottom: 0, width: "20%" }}>
          <Select bordered={false} disabled={true}>
            {listDevise.map((el, index) => {
              return (
                <Option key={index} value={el.id}>
                  {el.nom}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </Input.Group>
      {editAble && (
        <div className={styles.deleteButton} onClick={deleteItem}>
          <img src={DeleteIcon} alt="Delete" />
        </div>
      )}
    </Form>
  );
};

export default CoutsItem;
