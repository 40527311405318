/** @format */

import { Button, Col, Form, message, Row, Typography } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import * as lodash from "lodash";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { combineLatest, tap } from "rxjs";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectIsShowTutorial } from "../../../../app/reducers/indicatorTutorial.reducer";
import {
  selectChoixTransporter,
  selectLigne,
  selectLigneId,
  selectLigneRequest,
  updateLigne,
  updateLigneRequest,
  updateLineId,
} from "../../../../app/reducers/ligne.reducer";
import CustomCheckbox from "../../../../components/CustomCheckbox";
import IndicatorTutorialView from "../../../../components/IndicatorTutorial";
import SingleLoading from "../../../../components/SingleLoading";
import { CoutsAdditionnelAPI } from "../../../../domain/apis/coutsAdditionnel.api";
import { DeviseAPI } from "../../../../domain/apis/devise.api";
import { UniteAPI } from "../../../../domain/apis/unite.api";
import { makeRandom } from "../../../../helper/makeRandom";
import { useRxEffect } from "../../../../helper/rxjs.helper";
import { Budget, CoutsAdditionnel, Devise, Unite } from "../../../../models";
import UpdateLigne from "../../UpdateLigne";
import styles from "./Budget.module.css";
import BudgetItem from "./BudgetItem";
import CoutsItem from "./CoutsItem";
import PopupConfirm from "../../../../components/PopupConfirm";

const { Title } = Typography;

let BugetSubmitArr: boolean[] = [];
let CoutSubmitArr: boolean[] = [];
let impactTransporteurs: { nom?: string; prixConvenu?: number | null; budgetTransport?: number }[] = [];

const BudgetView = (props: { editable: boolean }) => {
  const { editable } = props;
  const dispatch = useAppDispatch();
  const ligneId = useAppSelector(selectLigneId);
  const ligneData = useAppSelector(selectLigne);
  const ligneRequest = useAppSelector(selectLigneRequest);
  const choixTransporters = useAppSelector(selectChoixTransporter);
  const chargementsLigne = ligneData.chargement;

  const isShowTutorial = useAppSelector(selectIsShowTutorial);
  const [form] = Form.useForm();

  const [plageState, setPlageState] = useState(false);
  const [layout, setLayout] = useState<number>(8);
  const [actionSave, setActionSave] = useState<boolean>(false);
  const [budgetData, setBudgetData] = useState<Budget[]>([{ id: undefined }]);
  const [coutsList, setCoutList] = useState<CoutsAdditionnel[]>([{}]);
  const [listDevise, setListDevise] = useState<Devise[]>([]);
  const [listUnit, setListUnit] = useState<Unite[]>([]);
  const [submitArr, setSubmitArr] = useState(-1);
  const [coutState, setCoutState] = useState<boolean>(false);
  const [listCountOptions, setListCountOptions] = useState<string[]>([]);
  const sliderRef = useRef<Slider | null>(null);
  const [listCountAvailable, setListCountAvailable] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [settings, setSettings] = useState({
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const [showPopupEditBudget, setShowPopupEditBudget] = useState<boolean>(false);
  const [firstItem, setFirstItem] = useState<Budget>();

  useEffect(() => {
    setIsSubmit(false);
  }, []);

  useRxEffect(
    () =>
      CoutsAdditionnelAPI.get({
        filter: {
          where: {
            isBase: true,
          },
        },
      }).pipe(
        tap({
          next: (res: CoutsAdditionnel[]) => {
            const coutType = res.map((el) => el.type);
            setListCountOptions(coutType as string[]);
            setListCountAvailable(coutType as string[]);
          },
        })
      ),
    []
  );

  useEffect(() => {
    if (ligneData.budget && ligneData.budget.length > 0) {
      setBudgetData(ligneData.budget);
    }
    if (ligneData.plages) {
      setPlageState(ligneData.plages);
    }
    if (ligneData.checkCoutsAdditionnels) {
      setCoutState(ligneData.checkCoutsAdditionnels);
    }
    if (ligneData.coutsAdditionnel) {
      setCoutList(ligneData.coutsAdditionnel);
      const typeCoutAdditionnel = ligneData.coutsAdditionnel.map((item) => (item.type ? item.type : ""));
      const differenceTypeCoutAdditionnel = lodash.difference(listCountOptions, typeCoutAdditionnel);
      setListCountOptions(differenceTypeCoutAdditionnel);
    }
    if (ligneData.idLigne) {
      dispatch(updateLineId({ ligneId: ligneData.idLigne }));
    }
    form.setFieldsValue({
      lineaire: ligneData.lineaire,
      checkCoutsAdditionnels: ligneData.checkCoutsAdditionnels,
    });
  }, [ligneData]);

  useEffect(() => {
    if (budgetData.length > 1) {
      setLayout(16);
      setSettings({
        ...settings,
        slidesToShow: 2,
      });
    } else {
      setLayout(8);
      setSettings({
        ...settings,
        slidesToShow: 1,
      });
    }
  }, [budgetData]);

  useEffect(() => {
    changeListCoutsAvailable();
  }, [coutsList]);

  useEffect(() => {
    setActionSave(false);
    if (BugetSubmitArr.length === budgetData.length) {
      if (coutState && CoutSubmitArr.length !== coutsList.length) {
        return;
      }

      if (submitArr < 0) {
        return;
      }
      if (BugetSubmitArr.every((el) => el) && CoutSubmitArr.every((el) => el)) {
        if (CoutSubmitArr.length > 0 && CoutSubmitArr.some((el) => !el)) {
          return;
        }

        // [B77] - AC 6: Edit Budget section
        impactTransporteurs = [];
        budgetData.forEach((budget) => {
          // find choix transporter by budget chargement dd and have prixConvenu > budgetTransport
          const prevBudgeTrans = ligneData?.budget?.find((item) => item.id === budget.id)?.budgetTransport;
          const choixTransportersByBudget = choixTransporters
            .filter(
              (choixTransporter) =>
                choixTransporter.caracteristiquesLigne?.chargementId == budget.chargementId &&
                (prevBudgeTrans ?? 0) >= (choixTransporter.prixConvenu ?? 0) &&
                (budget.budgetTransport ?? 0) < (choixTransporter.prixConvenu ?? 0)
            )
            .map((choixTransporter) => {
              return {
                nom: choixTransporter.transporteur?.nom,
                prixConvenu: choixTransporter.prixConvenu,
                budgetTransport: budget.budgetTransport,
              };
            });
          if (choixTransportersByBudget.length > 0) {
            impactTransporteurs.push(...choixTransportersByBudget);
          }
        });
        if (impactTransporteurs.length > 0) {
          setShowPopupEditBudget(true);
        } else {
          onSubmit();
        }

        return;
      }
      sliderRef.current?.slickGoTo(BugetSubmitArr.indexOf(false));
      message.error("Merci de compléter toutes les colonnes.").then((r) => console.log(r));
      return;
    }
  }, [submitArr]);

  useRxEffect(() => {
    setLoading(true);
    return combineLatest([DeviseAPI.fetchAll(), UniteAPI.fetchAll()]).pipe(
      tap({
        next: ([devises, unites]: [Devise[], Unite[]]) => {
          setListDevise(devises);
          setListUnit(unites);
          setLoading(false);
        },
      })
    );
  }, []);

  const changeChechcout = (e: CheckboxChangeEvent) => {
    setCoutState(e.target.checked);

    if (!e.target.checked) {
      setCoutList([]);
    }
  };

  const onFinish = () => {
    setActionSave(true);
    setSubmitArr(Math.random);
    BugetSubmitArr = [];
    CoutSubmitArr = [];
  };

  const onSubmit = () => {
    const values = form.getFieldsValue();
    const data = {
      idLigne: ligneData.idLigne ? ligneData.idLigne : ligneId.substring(0, 16) + makeRandom(4),
      lineaire: values.lineaire,
      checkCoutsAdditionnels: values.checkCoutsAdditionnels,
      isCompleted: true,
      budget: budgetData,
      coutsAdditionnel: coutsList,
      currentStage: 9,
    };
    dispatch(
      updateLigneRequest({
        ligneRequest: {
          ...ligneRequest,
          ...data,
        },
      })
    );
    dispatch(
      updateLigne({
        ligne: {
          ...ligneData,
          ...data,
        },
      })
    );
    setIsSubmit(true);
  };

  const onFinishFailed = () => {
    message.error("Merci de compléter toutes les colonnes.").then((r) => console.log(r));
  };

  const bugetSubmit = (index: number, statusItem: boolean) => {
    setTimeout(() => {
      BugetSubmitArr[index] = statusItem;
      setSubmitArr(index);
    }, index * 10);
  };

  const coutSubmit = (index: number, statusItem: boolean) => {
    setTimeout(() => {
      CoutSubmitArr[index] = statusItem;
      setSubmitArr(index);
    }, index * 10);
  };

  const addCouts = () => {
    if (coutsList.length >= 10) {
      message.error("Vous ne pouvez plus ajouter!").then();
      return;
    }
    setCoutList([...coutsList, {}]);
  };

  const removeCouts = (index: number) => {
    const currentList = [...coutsList];
    currentList.splice(index, 1);
    setCoutList(currentList);
  };

  const changeCoutsList = (index: number, type: string) => {
    const coutsListState = coutsList;
    // create copy coutsList[index] object
    const coutsListStateCopyObject = { ...coutsListState[index] };
    coutsListStateCopyObject.type = type;

    // create copy coutsList array
    const coutsListStateCopyArray = [...coutsListState];

    // replace coutsList[index] by index
    coutsListStateCopyArray.splice(index, 1, coutsListStateCopyObject);

    setCoutList(coutsListStateCopyArray);
  };
  const changeListCoutsAvailable = () => {
    const coutsSelected: string[] = [];
    coutsList.map((item) => {
      if (item.type) {
        coutsSelected.push(item.type);
      }
    });
    // const difference = lodash.difference(listCountAvailable, coutsSelected);

    const countAvailable: string[] = [];

    listCountOptions.forEach((el) => {
      if (!coutsSelected.includes(el)) {
        countAvailable.push(el);
      }
    });
    setListCountAvailable(countAvailable);
  };

  const updateItem = (index: number, itemData: Budget) => {
    setBudgetData((prevState) => {
      const copyBudgetData = [...prevState];
      copyBudgetData[index] = itemData;
      return copyBudgetData;
    });
  };

  const updateCoutsItem = (index: number, itemData: CoutsAdditionnel) => {
    setCoutList((prevState) => {
      const copyCoutData = [...prevState];
      copyCoutData[index] = itemData;
      return copyCoutData;
    });
  };

  return (
    <>
      {loading && <SingleLoading />}
      {isSubmit && <UpdateLigne />}
      <PopupConfirm
        visible={showPopupEditBudget}
        title="Attention"
        okText="Oui"
        cancelText="Non"
        onOk={onSubmit}
        onCancel={() => setShowPopupEditBudget(false)}
        showDetail
        hideClose
        childrenDetail={
          <>
            {impactTransporteurs.map((impactTransporteur, index) => (
              <h3 key={`impactTransporteur${index}`}>
                {impactTransporteur.nom} Prix Transporteur : {impactTransporteur.prixConvenu} Budget : {impactTransporteur.budgetTransport}
              </h3>
            ))}
          </>
        }
      >
        <>
          <p>Le Budget Transport devient plus petit que certains prix convenus avec des Transporteurs. Souhaitez-vous continuer ?</p>
          <p>Ceci signifie que, si le prix des Transporteurs concernés n&apos;est pas renégocié, la marge attendue ne sera pas respectée.</p>
        </>
      </PopupConfirm>
      <Row>
        <Col span={8} offset={8}>
          <Title level={3} className={styles.title}>
            Budget
          </Title>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          {plageState && <div className={styles.plageOn} />}
          <ul className={styles.lableBudget}>
            <li>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                Prix contractualisé
                {isShowTutorial ? (
                  <div style={{ marginLeft: "0.5rem" }}>
                    <IndicatorTutorialView hint={"Prix contractualisé pour cette plage avec le Client."} />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </li>
            <li>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                Marge visée
                {isShowTutorial ? (
                  <div style={{ marginLeft: "0.5rem" }}>
                    <IndicatorTutorialView hint={"Marge Transalliance visée."} />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </li>
          </ul>
          <ul className={styles.lableBudget}>
            <li>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                Budget Transport
                {isShowTutorial ? (
                  <div style={{ marginLeft: "0.5rem" }}>
                    <IndicatorTutorialView
                      hint={"Budget pour le Transport sous-traitant par lot, déduit du prix contractualisé et de la marge visée."}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </li>
          </ul>
        </Col>
        <Col span={layout} className={styles.groupBudget}>
          <Slider {...settings} ref={sliderRef}>
            {budgetData.map((el, index) => {
              if (plageState || index < 1) {
                return (
                  <div key={index}>
                    <BudgetItem
                      index={index}
                      itemData={el}
                      actionSave={actionSave}
                      plageState={plageState}
                      listDevise={listDevise}
                      listUnit={listUnit}
                      itemCallBack={(index, item) => updateItem(index, item)}
                      submitCallBack={(index: number, statusItem: boolean) => bugetSubmit(index, statusItem)}
                      isShowTutorial={isShowTutorial}
                      chargementsLigne={chargementsLigne}
                      editAble={editable}
                      callBackChange={(value) => {
                        setFirstItem(value);
                      }}
                      firstItem={firstItem}
                    />
                  </div>
                );
              }
            })}
          </Slider>
        </Col>
      </Row>
      <Form form={form} name="bugetForm" onFinishFailed={onFinishFailed} onFinish={onFinish} disabled={!editable}>
        <Row style={{ marginBottom: "0.525rem", marginTop: "1rem" }}>
          <Col span={8} style={{ textAlign: "right", paddingRight: "2rem" }}>
            {budgetData.length > 1 && <div style={{ padding: "5px 0" }}>Payant pour</div>}
          </Col>
          <Col span={8}>
            {budgetData.length > 1 && (
              <Form.Item valuePropName="checked" name="lineaire" style={{ marginBottom: 0 }}>
                <CustomCheckbox disabled={!editable}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Linéaire
                    {isShowTutorial ? (
                      <div style={{ marginLeft: "0.5rem" }}>
                        <IndicatorTutorialView hint={"À cocher si le payant pour linéaire s'applique."} />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </CustomCheckbox>
              </Form.Item>
            )}
            <Form.Item valuePropName="checked" name="checkCoutsAdditionnels" style={{ marginBottom: 0 }}>
              <CustomCheckbox onChange={(e) => changeChechcout(e)} className="whitespace-nowrap" disabled={!editable}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  Coûts additionnels
                  {isShowTutorial && (
                    <div style={{ marginLeft: "0.5rem" }}>
                      <IndicatorTutorialView hint={"À cocher si des coûts additionnels sont à ajouter pour la ligne donnée."} />
                    </div>
                  )}
                </div>
              </CustomCheckbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row style={{ justifyContent: budgetData.length > 1 ? "center" : "flex-start" }}>
        <Col offset={8} span={8}>
          {coutState && (
            <div className={styles.groupCoutAdd}>
              {editable && (
                <Button type="primary" className={`${styles.addButton} ${styles.buttonFull} justify-center`} onClick={addCouts}>
                  Ajouter Ligne
                </Button>
              )}
              {coutsList.map((el, index) => {
                return (
                  <CoutsItem
                    key={index}
                    actionSave={actionSave}
                    index={index}
                    itemData={el}
                    listDevise={listDevise}
                    listCountAvailable={listCountAvailable}
                    coutsList={coutsList}
                    itemCallBack={(index, item) => updateCoutsItem(index, item)}
                    changeListCoutsAvailable={changeListCoutsAvailable}
                    changeCoutsList={changeCoutsList}
                    parentCallback={(item) => removeCouts(item)}
                    submitCallBack={(index: number, statusItem: boolean) => coutSubmit(index, statusItem)}
                    editAble={editable}
                    budgetItem={firstItem}
                  />
                );
              })}
            </div>
          )}
          <Form.Item className={styles.submit}>
            {editable && (
              <Button type="primary" htmlType="submit" className={`${styles.bt} ${styles.buttonFull}`} onClick={form.submit}>
                Confirmer
              </Button>
            )}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default BudgetView;
